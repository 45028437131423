<template>
  <div class="index">
    <section>
      <!-- 左图 -->
      <div class="left"></div>
      <!-- 右登录 -->
      <div class="right">
        <h4>欢迎登录</h4>
        <!-- 表单 -->
        <el-form :model="form" label-width="60px" label-position="left" ref="form" :rules="rules" size="medium" @keyup.enter.native="login('form')">
          <el-form-item label="账号:" prop="tel">
            <el-input v-model="form.tel" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="userPwd">
            <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
          </el-form-item>
        </el-form>

        <!-- 登录按钮 -->
        <el-button type="primary" :loading="loading" :disabled="disabled" @click="login('form')">{{ text }}</el-button>

        <!-- 忘记密码 -->
        <!-- <div style="margin: 22px 0">
          <el-link type="primary">忘记密码？</el-link>
        </div> -->

        <!-- 分割线 -->
        <!--<el-divider style="margin-top:44px">扫码关注</el-divider>-->

        <!-- 移动端二维码 -->
        <!--<el-image :src="src" fit="contain"></el-image>-->
      </div>
    </section>
    <!-- 标注 -->
    <footer><a href="https://beian.miit.gov.cn" target="_blank">版权所有©2016 福建省新泽尔资讯科技有限公司 闽ICP备06014593号-4</a></footer>
  </div>
</template>

<script>
import {setToken} from "../../utils/cookie";

export default {
  name: 'login',
  data() {
    return {
      form: {
        tel: '',
        password: ''
      },
      text: '登录',
      loading: false,
      disabled: false,
      rules: {
        tel: [
          {required: true, message: '账号不能为空', trigger: 'submit'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'submit'}
        ]
      }
    }
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.text = '登录中'
          this.loading = true
          this.disabled = true
          this.$store.dispatch('user/login', this.form).then(res => {
            if (res && res.code === 1) {
              this.$router.push({path: '/'})
            } else {
              this.$message({
                message: res.message,
                type: "error"
              })
            }
          }).catch(error => {
            this.$message({
              message: error,
              type: "error"
            })
          }).finally(() => {
            this.text = "登录";
            this.loading = false;
            this.disabled = false;
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
$padding: 30px;
$marginTop: 30px;

.index {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/login_back.png") left center / cover fixed;

  section {
    position: fixed;
    width: 960px;
    height: 560px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 6px;
    background-color: #fff;

    .left {
      width: 50%;
      height: 100%;
      float: left;
      background: url("../../assets/images/left.png");
      background-size: 100% 100%;
    }

    .right {
      width: 50%;
      height: 100%;
      float: right;
      padding: $padding;
      box-sizing: border-box;

      h4 {
        font-size: 18px;
      }

      .el-form {
        margin-top: $marginTop;
      }

      // 按钮样式设计
      .el-button {
        width: 100%;
        height: 40px;
        margin-bottom: 40px;
        box-sizing: border-box;
      }

      // 二维码
      .el-image {
        width: 94px;
        height: 94px;
        margin: 0 auto;
        display: block;
      }


    }
  }
}

// 标注
footer {
  //margin-top: $marginTop;
  color: #FFFFFF;
  line-height: 22px;
  position: fixed;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  a {
    color: #FFFFFF;

    &:hover {
      color: #999999;
    }
  }
}

.el-icon-loading {
  width: 13px;
  height: 13px;
}
</style>
